








import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    translucent: {
      type: Boolean,
      default: false,
    },

    curveEdge: {
      type: Boolean,
      default: false,
    },

    primary: {
      type: Boolean,
      default: false,
    },
  },
})
export default class Loader extends Vue {}
