



















import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    value: {
      type: Number,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    sub: {
      type: String,
      default: "",
    },

    min: {
      type: Number,
      default: 0,
    },

    max: {
      type: Number,
      default: 99,
    },
  },
})
export default class CountInputField extends Vue {}
