







import { Component, Vue } from "vue-property-decorator";
import i18n, { SUPPORTED_LOCALES } from "@/i18n";

@Component({})
export default class ChooseLanguage extends Vue {
  selected = i18n.locale;

  saveLanguage(event: Event) {
    const selected = (event.target as HTMLSelectElement).selectedOptions[0];
    this.selected = i18n.locale = selected.value;
    localStorage.setItem("locale", i18n.locale);
  }

  get supportedLanguages() {
    return SUPPORTED_LOCALES;
  }
}
