


















import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    withExitLink: {
      type: Boolean,
      default: false,
    },
  },
})
export default class SplashLayout extends Vue {
  appVersion = process.env.VUE_APP_VERSION;
}
